import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import GoalList from './components/GoalList';
import TransitionWrapper from './components/TransitionWrapper';
import ProtectedRoute from './components/ProtectedRoute';
import SharedProgress from './components/SharedProgress';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';
import Profile from './components/Profile';
import CompleteProfile from './components/CompleteProfile';
import AdminDashboard from './components/admin/AdminDashboard'; // Import the AdminDashboard component
import api from './api';
import { setToken, removeToken, getToken } from './auth';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
    const [currentStreak, setCurrentStreak] = useState(0);
    const [profileComplete, setProfileComplete] = useState(false);
    const [role, setRole] = useState(null); // Track the user's role
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStreak = async () => {
            try {
                const { data } = await api.get('/api/streak');
                setCurrentStreak(data.streak);
            } catch (error) {
                console.error('Error fetching streak:', error);
            }
        };

        if (isAuthenticated) {
            fetchStreak();
        }
    }, [isAuthenticated]);

    const handleLogin = (token, profileComplete, userRole) => {
        setToken(token);
        setIsAuthenticated(true);
        setProfileComplete(profileComplete);
        setRole(userRole);

        if (userRole === 'admin') {
            navigate('/admin/dashboard');
        } else if (profileComplete) {
            navigate('/dashboard');
        } else {
            navigate('/complete-profile');
        }
    };

    const handleProfileUpdate = () => {
        setProfileComplete(true);
        navigate('/dashboard');
    };

    const handleLogout = () => {
        removeToken();
        setIsAuthenticated(false);
        setProfileComplete(false);
        setRole(null);
        navigate('/login');
    };

    return (
        <TransitionWrapper>
            <Routes>
                <Route path="/" element={isAuthenticated ? (role === 'admin' ? <Navigate to="/admin/dashboard" /> : (profileComplete ? <Navigate to="/dashboard" /> : <Navigate to="/complete-profile" />)) : <Login onLogin={handleLogin} />} />
                <Route path="/login" element={isAuthenticated ? (role === 'admin' ? <Navigate to="/admin/dashboard" /> : (profileComplete ? <Navigate to="/dashboard" /> : <Navigate to="/complete-profile" />)) : <Login onLogin={handleLogin} />} />
                <Route path="/register" element={isAuthenticated ? (profileComplete ? <Navigate to="/dashboard" /> : <Navigate to="/complete-profile" />) : <Register />} />
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard currentStreak={currentStreak} setCurrentStreak={setCurrentStreak} onLogout={handleLogout} /></ProtectedRoute>} />
                <Route path="/goals" element={<ProtectedRoute><GoalList /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><Profile onProfileUpdate={handleProfileUpdate} /></ProtectedRoute>} />
                <Route path="/complete-profile" element={<ProtectedRoute><CompleteProfile onProfileUpdate={handleProfileUpdate} /></ProtectedRoute>} />
                <Route path="/api/shared-progress/:share_id" element={<SharedProgress />} />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/admin/dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} /> {/* Add the admin dashboard route */}
                <Route path="*" element={<NotFound />} /> {/* Add this catch-all route for 404 page */}
            </Routes>
        </TransitionWrapper>
    );
};

export default App;
