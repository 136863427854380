import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash.debounce';
import { useSnackbar } from 'notistack';
import './Login.css';

const Register = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [location, setLocation] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [emailAvailable, setEmailAvailable] = useState(true);
    const [usernameAvailable, setUsernameAvailable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(false);

    const emailId = uuidv4();
    const usernameId = uuidv4();
    const passwordId = uuidv4();
    const confirmPasswordId = uuidv4();
    const locationId = uuidv4();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            enqueueSnackbar('Passwords do not match!', { variant: 'error' });
            return;
        }

        if (!emailAvailable || !usernameAvailable) {
            enqueueSnackbar('Email or Username not available!', { variant: 'error' });
            return;
        }

        if (!selectedSuggestion) {
            enqueueSnackbar('Please select a location from the suggestions!', { variant: 'error' });
            return;
        }

        setLoading(true);

        try {
            const response = await fetch('https://goaltracker-92af4b515b01.herokuapp.com/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    username,
                    password,
                    location
                }),
            });
            const data = await response.json();
            setLoading(false);
            if (response.ok) {
                enqueueSnackbar(data.message || 'Registered successfully!', { variant: 'success' });
                // Clear form data upon successful registration
                setEmail('');
                setUsername('');
                setPassword('');
                setConfirmPassword('');
                setLocation('');
                setSuggestions([]);
            } else {
                enqueueSnackbar(data.error || 'Failed to register!', { variant: 'error' });
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar('Failed to register!', { variant: 'error' });
        }
    };

    const checkAvailability = async (field, value) => {
        try {
            const response = await fetch(`https://goaltracker-92af4b515b01.herokuapp.com/auth/check-${field}?value=${value}`);
            const data = await response.json();
            if (response.ok) {
                if (field === 'email') {
                    setEmailAvailable(data.available);
                } else if (field === 'username') {
                    setUsernameAvailable(data.available);
                }
            } else {
                enqueueSnackbar(data.error || `Failed to check ${field} availability!`, { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar(`Failed to check ${field} availability!`, { variant: 'error' });
        }
    };

    // Include checkAvailability in the dependency array
    const debouncedCheckAvailability = useCallback(debounce(checkAvailability, 500), [checkAvailability]);

    const fetchSuggestions = async (query) => {
        if (!query) return;
        try {
            const response = await fetch(`https://goaltracker-92af4b515b01.herokuapp.com/auth/location-suggestions?query=${query}`);
            const data = await response.json();
            if (response.ok) {
                setSuggestions(data.features.map(feature => ({
                    location: `${feature.properties.city}, ${feature.properties.country}`
                })));
                setSelectedSuggestion(false);
            } else {
                enqueueSnackbar(data.error || 'Failed to fetch suggestions!', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Failed to fetch suggestions!', { variant: 'error' });
        }
    };

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
        fetchSuggestions(e.target.value);
    };

    const handleSuggestionClick = (suggestion) => {
        setLocation(suggestion.location);
        setSuggestions([]);
        setSelectedSuggestion(true);
    };

    const handleLocationBlur = () => {
        if (!selectedSuggestion) {
            setLocation('');
            setSuggestions([]);
        }
    };

    const handleLocationFocus = () => {
        if (location) {
            fetchSuggestions(location);
        }
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsername(value);
        if (value.trim() !== '') {
            debouncedCheckAvailability('username', value);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 font-poppins relative">
            <div className="absolute inset-0 flex justify-center items-center">
                <div className="absolute bg-blue-300 rounded-full h-96 w-96 -top-20 -left-40 opacity-30"></div>
                <div className="absolute bg-green-300 rounded-full h-80 w-80 -bottom-20 -right-40 opacity-30"></div>
            </div>
            <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md z-10">
                <h1 className="text-2xl mb-6 text-center font-semibold">Register</h1>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor={emailId}>Email</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded relative">
                        <i className="fas fa-envelope mr-2 text-gray-500"></i>
                        <input
                            id={emailId}
                            type="email"
                            placeholder="Email"
                            className={`w-full focus:outline-none smooth-text ${!emailAvailable ? 'border-red-500' : ''}`}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                debouncedCheckAvailability('email', e.target.value);
                            }}
                        />
                        {!emailAvailable && <span className="text-red-500 ml-2 absolute right-2">✖</span>}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor={usernameId}>Username</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded relative">
                        <i className="fas fa-user mr-2 text-gray-500"></i>
                        <input
                            id={usernameId}
                            type="text"
                            placeholder="Username"
                            className={`w-full focus:outline-none smooth-text ${!usernameAvailable ? 'border-red-500' : ''}`}
                            value={username}
                            onChange={handleUsernameChange}
                        />
                        {!usernameAvailable && <span className="text-red-500 ml-2 absolute right-2">✖ Not available</span>}
                        {usernameAvailable && username && <span className="text-green-500 ml-2 absolute right-2">✔ Available</span>}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor={passwordId}>Password</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-lock mr-2 text-gray-500"></i>
                        <input
                            id={passwordId}
                            type="password"
                            placeholder="Password"
                            className="w-full focus:outline-none smooth-text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor={confirmPasswordId}>Confirm Password</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-lock mr-2 text-gray-500"></i>
                        <input
                            id={confirmPasswordId}
                            type="password"
                            placeholder="Confirm Password"
                            className="w-full focus:outline-none smooth-text"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-4 relative">
                    <label className="block mb-2 text-gray-600" htmlFor={locationId}>Location <span className="text-sm text-gray-500">(used to determine your timezone)</span></label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-map-marker-alt mr-2 text-gray-500"></i>
                        <input
                            id={locationId}
                            type="text"
                            placeholder="City, Country"
                            className="w-full focus:outline-none smooth-text"
                            value={location}
                            onChange={handleLocationChange}
                            onBlur={handleLocationBlur}
                            onFocus={handleLocationFocus}
                        />
                    </div>
                    {suggestions.length > 0 && (
                        <ul className="border border-gray-300 rounded mt-2 bg-white max-h-48 overflow-auto absolute z-20 w-full" onMouseDown={(e) => e.preventDefault()}>
                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className="p-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.location}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <button type="submit" className="bg-blue-500 text-white py-3 px-6 rounded-full w-full font-semibold flex items-center justify-center" disabled={loading}>
                    {loading ? (
                        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                        </svg>
                    ) : (
                        <>
                            <i className="fas fa-user-plus mr-2"></i> Register
                        </>
                    )}
                </button>
                <div className="text-center mt-4">
                    <span className="text-gray-600">Already have an account? </span>
                    <Link to="/login" className="text-blue-500 hover:underline">
                        Login
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default Register;
