import axios from 'axios';
import { getToken, setToken, removeToken } from './auth';

const api = axios.create({
  baseURL: 'https://goaltracker-92af4b515b01.herokuapp.com',
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Skip interceptor for login endpoint
    if (originalRequest.url === '/auth/login') {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axios.post('https://goaltracker-92af4b515b01.herokuapp.com/auth/refresh', {}, { withCredentials: true });
        setToken(data.access_token);
        originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`;
        return api(originalRequest);
      } catch (err) {
        removeToken();
        window.location.href = '/login'; // Redirect to login on token refresh failure
      }
    }
    return Promise.reject(error);
  }
);

export default api;
