import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import './Login.css'; // Ensure this file has responsive styles if needed
import ForgotPasswordModal from './ForgotPasswordModal';
import api from '../api'; // Import the api module

const Login = ({ onLogin }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const emailId = uuidv4();
    const passwordId = uuidv4();
    const rememberMeId = uuidv4();

    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await api.get('/auth/user');
                    if (response.status === 200) {
                        const userRole = response.data.role;
                        if (userRole === 'admin') {
                            navigate('/admin/dashboard');
                        } else {
                            navigate('/dashboard');
                        }
                    }
                } catch (error) {
                    console.error('Token check failed:', error);
                }
            }
        };

        checkToken();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/auth/login', { email, password });
            const { access_token, username, location, role } = response.data;

            enqueueSnackbar(response.data.message || 'Logged in successfully!', { variant: 'success' });
            localStorage.setItem('token', access_token);

            setTimeout(() => {
                onLogin(access_token, !!username && !!location, role); // Pass the role to onLogin
                if (role === 'admin') {
                    navigate('/admin/dashboard');
                } else {
                    navigate('/dashboard');
                }
            }, 2000);
        } catch (error) {
            enqueueSnackbar(error.response?.data?.error || 'Failed to log in!', { variant: 'error' });
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 font-poppins relative">
            {/* SVG Background Decoration with Subtle Organic Shapes */}
            <svg
                className="absolute inset-0 w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 800 600"
                preserveAspectRatio="xMidYMid slice"
            >
                <path
                    d="M 0 300 C 150 200 350 400 500 300 C 650 200 850 400 1000 300 L 1000 600 L 0 600 Z"
                    fill="#E5F4E3"
                    opacity="0.7"
                />
                <path
                    d="M 0 600 C 200 500 400 700 600 600 C 800 500 1000 700 1200 600 L 1200 800 L 0 800 Z"
                    fill="#C1E1C1"
                    opacity="0.7"
                />
            </svg>

            {/* Login Form */}
            <form 
                onSubmit={handleSubmit} 
                className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-11/12 max-w-md z-10 relative"
                style={{ width: '90%', maxWidth: '400px' }}>
                <h1 className="text-2xl mb-6 text-center font-semibold">Login</h1>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor={emailId}>Email</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-envelope mr-2 text-gray-500"></i>
                        <input
                            id={emailId}
                            type="email"
                            placeholder="Email"
                            className="w-full focus:outline-none smooth-text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor={passwordId}>Password</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-lock mr-2 text-gray-500"></i>
                        <input
                            id={passwordId}
                            type="password"
                            placeholder="Password"
                            className="w-full focus:outline-none smooth-text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-4 flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id={rememberMeId}
                            className="mr-2"
                        />
                        <label htmlFor={rememberMeId} className="text-gray-600">Remember Me</label>
                    </div>
                    <button
                        type="button"
                        onClick={() => setIsModalOpen(true)}
                        className="text-blue-500 hover:underline text-sm"
                    >
                        Forgot Password?
                    </button>
                </div>
                <button type="submit" className="bg-blue-500 text-white py-3 px-6 rounded-full w-full font-semibold flex items-center justify-center">
                    <i className="fas fa-sign-in-alt mr-2"></i> Login
                </button>
                <div className="text-center mt-4">
                    <span className="text-gray-600">Don't have an account? </span>
                    <Link to="/register" className="text-blue-500 hover:underline">
                        Register
                    </Link>
                </div>
            </form>

            {/* Forgot Password Modal */}
            <ForgotPasswordModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default Login;
