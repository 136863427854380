import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import Header from './Header';
import GoalList from './GoalList';
import OtherGoalsList from './OtherGoalsList';
import Section from './Section';
import GoalProgressChart from './GoalProgressChart';
import Leaderboard from './Leaderboard';
import ShareProgressCard from './ShareProgressCard';
import api from '../api'; // Import the api instance

const Dashboard = ({ currentStreak, setCurrentStreak, onLogout }) => {
    const [user, setUser] = useState(null);
    const [goals, setGoals] = useState([]);
    const [leaderboard, setLeaderboard] = useState([]);
    const [longestStreak, setLongestStreak] = useState(0);
    const [isShareProgressOpen, setIsShareProgressOpen] = useState(false);
    const [progressData, setProgressData] = useState([]);
    const [userRank, setUserRank] = useState(null); // Add state for userRank
    const [showScrollTopButton, setShowScrollTopButton] = useState(false); // State for showing the scroll-to-top button
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/auth/user');
                setUser(response.data);

                if (!response.data.username || !response.data.location) {
                    navigate('/complete-profile');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                navigate('/login', { replace: true });
            }
        };

        fetchUser();
    }, [navigate]);

    const fetchGoals = async () => {
        try {
            const response = await api.get('/api/goals');
            setGoals(response.data);
        } catch (error) {
            console.error('Error fetching goals:', error);
        }
    };

    const fetchLeaderboard = async () => {
        try {
            const response = await api.get('/api/leaderboard');
            setLeaderboard(response.data);
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };

    const fetchProgressData = async () => {
        try {
            const response = await api.get('/api/share-progress');
            const data = response.data;
            setProgressData(data.progress_data || []);  // Ensure it's an array
            setCurrentStreak(data.user_streak.current_streak);
            setLongestStreak(data.user_streak.longest_streak);
            setUserRank(data.user_rank); // Set the userRank from the response
            setIsShareProgressOpen(true);
        } catch (error) {
            console.error('Error fetching progress data:', error);
        }
    };

    const updateLeaderboardUsername = (newUsername) => {
        setLeaderboard((prevLeaderboard) =>
            prevLeaderboard.map((user) =>
                user.currentUser ? { ...user, name: newUsername } : user
            )
        );
    };

    useEffect(() => {
        fetchGoals();
        fetchLeaderboard();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollTopButton(true);
            } else {
                setShowScrollTopButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Header
                refreshGoals={fetchGoals}
                refreshLeaderboard={fetchLeaderboard}
                currentStreak={currentStreak}
                setCurrentStreak={setCurrentStreak}
                updateLeaderboardUsername={updateLeaderboardUsername}
                fetchProgressData={fetchProgressData}
                onLogout={onLogout}
            />
            <div className="min-h-screen p-4 bg-gray-100 font-poppins">
                <Section title="Your Goals">
                    <div className="flex flex-col lg:flex-row">
                        <div className="flex-1">
                            <GoalList
                                goals={goals}
                                setGoals={setGoals}
                                refreshLeaderboard={fetchLeaderboard}
                                refreshGoals={fetchGoals}
                                setCurrentStreak={setCurrentStreak}
                            />
                        </div>
                        <div className="lg:w-1/5 mt-4 lg:mt-0 lg:ml-4">
                            <Leaderboard leaderboard={leaderboard} />
                        </div>
                    </div>
                </Section>
                <Section title="Goal Progress Chart">
                    <GoalProgressChart goals={goals} />
                </Section>
                <Section title="">
                    <OtherGoalsList />
                </Section>
            </div>
            {isShareProgressOpen && (
                <ShareProgressCard
                    progressData={progressData}
                    onClose={() => setIsShareProgressOpen(false)}
                    userStreak={{ current_streak: currentStreak, longest_streak: longestStreak }}
                    userRank={userRank} // Pass the userRank here
                />
            )}
            {showScrollTopButton && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-8 right-8 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    <FaArrowUp />
                </button>
            )}
        </div>
    );
};

export default Dashboard;
