import React, { useState, useEffect, useRef, Fragment } from 'react';
import { CalendarDaysIcon, EllipsisVerticalIcon, CameraIcon } from '@heroicons/react/24/solid';
import { Menu, Transition, Dialog } from '@headlessui/react';
import confetti from 'canvas-confetti';
import EditGoalForm from './EditGoalForm';
import ImageUploadPrompt from './ImageUploadPrompt';
import { useSnackbar } from 'notistack';
import { toast } from 'react-toastify';
import api from '../api';

const GoalList = ({ goals, setGoals, refreshLeaderboard, refreshGoals, setCurrentStreak }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [userTimezone, setUserTimezone] = useState('UTC'); // Default to UTC
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [goalToDelete, setGoalToDelete] = useState(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [goalToEdit, setGoalToEdit] = useState(null);
    const [isImageUploadPromptOpen, setIsImageUploadPromptOpen] = useState(false);
    const [goalForImageUpload, setGoalForImageUpload] = useState(null);
    const [dayForImageUpload, setDayForImageUpload] = useState(null);
    const [isCollageDisplayOpen, setIsCollageDisplayOpen] = useState(false);
    const [collageUrl, setCollageUrl] = useState(null);
    const audioRef = useRef(null); // Create a ref for the audio element
    const completeAudioRef = useRef(null); // Create a ref for the complete audio element

    useEffect(() => {
        const fetchUserTimezone = async () => {
            try {
                const response = await api.get('/auth/user/timezone');
                const data = response.data;
                if (data.timezone) {
                    setUserTimezone(data.timezone);
                } else {
                    enqueueSnackbar('Failed to fetch user timezone', { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar('Error fetching user timezone', { variant: 'error' });
            }
        };

        fetchUserTimezone();
    }, []);

    const toggleComplete = async (goal, day) => {
        try {
            const response = await api.post(`/api/goals/${goal._id}/toggle-complete`, { day });
            const data = response.data;
            const isNowComplete = goal.progress[day]?.status !== 'completed';
            const { goalFullyCompleted } = data;

            toast.success(data.message);
            setGoals((prevGoals) =>
                prevGoals.map((g) =>
                    g._id === goal._id
                        ? {
                              ...g,
                              progress: {
                                  ...g.progress,
                                  [day]: {
                                      ...g.progress[day],
                                      status: isNowComplete ? 'completed' : 'not_completed',
                                      picture: g.progress[day]?.picture, // Keep existing picture if any
                                  },
                              },
                          }
                        : g
                )
            );
            if (refreshLeaderboard) refreshLeaderboard();

            // Play sound and show confetti only if the day is marked as completed
            if (isNowComplete) {
                if (goalFullyCompleted) {
                    if (completeAudioRef.current) {
                        completeAudioRef.current.play();
                    }
                } else {
                    if (audioRef.current) {
                        audioRef.current.play();
                    }
                }
                confetti({
                    particleCount: 800,
                    spread: 100,
                    origin: { y: 0.6 },
                });

                // Prompt image upload for the first and last days if no picture exists
                if ((day === '1' || day === goal.duration.toString()) && !goal.progress[day]?.picture) {
                    setGoalForImageUpload(goal);
                    setDayForImageUpload(day);
                    setIsImageUploadPromptOpen(true);
                }
            }

            // Update the current streak in the header
            setCurrentStreak(data.currentStreak); // Update currentStreak here
        } catch (error) {
            enqueueSnackbar('Error toggling goal completion status', { variant: 'error' });
        }
    };

    const handleImageUpload = async (goal, day, file) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('day', day); // Append the day to the form data

        try {
            const response = await api.post(`/api/goals/${goal._id}/upload-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const data = response.data;
            enqueueSnackbar(data.message, { variant: 'success' });

            // Update the goal's progress with the uploaded image
            setGoals((prevGoals) =>
                prevGoals.map((g) =>
                    g._id === goal._id
                        ? {
                              ...g,
                              progress: {
                                  ...g.progress,
                                  [day]: {
                                      ...g.progress[day],
                                      picture: data.pictureUrl,
                                  },
                              },
                          }
                        : g
                )
            );

            setIsImageUploadPromptOpen(false);
        } catch (error) {
            enqueueSnackbar('Error uploading image', { variant: 'error' });
        }
    };

    const generateCollage = async (goalId) => {
        try {
            const response = await api.post(`/api/goals/${goalId}/generate-collage`);
            const data = response.data;
            if (data.collageUrl) {
                setCollageUrl(data.collageUrl);
                setIsCollageDisplayOpen(true);
            } else {
                enqueueSnackbar('Failed to generate collage', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Error generating collage', { variant: 'error' });
        }
    };

    const getDayName = (dateString) => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const date = new Date(dateString);
        return daysOfWeek[date.getUTCDay()];
    };

    const generateSpecificDays = (goal) => {
        const { start_date, specific_days, duration } = goal;
        const specificDates = [];
        const startDate = new Date(start_date);
        for (let i = 0; i < duration; i++) {
            const currentDate = new Date(startDate);
            currentDate.setUTCDate(currentDate.getUTCDate() + i);
            if (specific_days?.includes(getDayName(currentDate.toISOString().split('T')[0]))) {
                specificDates.push(currentDate);
            }
        }
        return specificDates;
    };

    const generateEverydayDays = (goal) => {
        const { start_date, duration } = goal;
        const everydayDates = [];
        const startDate = new Date(start_date);
        for (let i = 0; i < duration; i++) {
            const currentDate = new Date(start_date);
            currentDate.setUTCDate(currentDate.getUTCDate() + i);
            everydayDates.push(currentDate);
        }
        return everydayDates;
    };

    const calculateCurrentDay = (startDate) => {
        const start = new Date(startDate).toISOString().split('T')[0];
        const now = new Date(new Date().toLocaleString('en-US', { timeZone: userTimezone })).toISOString().split('T')[0];
        const diffTime = Math.abs(new Date(now) - new Date(start));
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Difference in days
    };

    const isTodayOrBefore = (dateString) => {
        const today = new Date(new Date().toLocaleString('en-US', { timeZone: userTimezone })).toISOString().split('T')[0];
        const date = new Date(dateString).toISOString().split('T')[0];
        return new Date(date) <= new Date(today);
    };

    const calculateCompletionPercentage = (goal) => {
        const totalDays = Object.keys(goal.progress).length;
        const completedDays = Object.values(goal.progress).filter((progress) => progress.status === 'completed').length;
        return Math.round((completedDays / totalDays) * 100);
    };

    const calculateMissedDays = (goal) => {
        return Object.values(goal.progress).filter((progress) => progress.status === 'missed').length;
    };

    const getEmojiForMissedDays = (missedDays) => {
        if (missedDays === 0) return '😊'; // Smiling face if no missed days
        if (missedDays === 1) return '😟'; // Sad face if one missed day
        if (missedDays >= 2) return '😢'; // Very sad face if more than one missed day
    };

    const openEditForm = (goal) => {
        setGoalToEdit(goal);
        setIsEditFormOpen(true);
    };

    const openDeleteConfirm = (goal) => {
        setGoalToDelete(goal);
        setIsDeleteConfirmOpen(true);
    };

    const closeDeleteConfirm = () => {
        setGoalToDelete(null);
        setIsDeleteConfirmOpen(false);
    };

    const confirmDelete = async () => {
        if (!goalToDelete) return;
        try {
            const response = await api.delete(`/api/goals/${goalToDelete._id}`);
            if (response.status === 200) {
                enqueueSnackbar('Goal deleted successfully!', { variant: 'success' });
                setGoals(goals.filter((g) => g._id !== goalToDelete._id));
                if (refreshLeaderboard) refreshLeaderboard();
                if (refreshGoals) refreshGoals();
            } else {
                const data = response.data;
                enqueueSnackbar(data.error || 'Failed to delete goal', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Error deleting goal', { variant: 'error' });
        }
        closeDeleteConfirm();
    };

    return (
        <div className="min-h-12 p-4 bg-gray-100 font-poppins">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                {goals.length === 0 ? (
                    <p>No goals found</p>
                ) : (
                    goals.map((goal) => {
                        const currentDay = calculateCurrentDay(goal.start_date);
                        const completionPercentage = calculateCompletionPercentage(goal);
                        const missedDays = calculateMissedDays(goal);
                        const missedDaysEmoji = getEmojiForMissedDays(missedDays);
                        const firstDayPicture = goal.progress["1"]?.picture;
                        const lastDayPicture = goal.progress[goal.duration.toString()]?.picture;

                        return (
                            <div key={goal._id} className="bg-white p-6 rounded-lg shadow-lg flex flex-col relative">
                                <div className="absolute top-2 right-2">
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={() => openEditForm(goal)}
                                                                className={`${
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                } block w-full text-left px-4 py-2 text-sm`}
                                                            >
                                                                Edit
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={() => openDeleteConfirm(goal)}
                                                                className={`${
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                } block w-full text-left px-4 py-2 text-sm`}
                                                            >
                                                                Delete
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    {firstDayPicture && lastDayPicture && (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() => generateCollage(goal._id)}
                                                                    className={`${
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                    } block w-full text-left px-4 py-2 text-sm`}
                                                                >
                                                                    Generate Before and After Collage
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    )}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                <div className="flex items-center mb-4">
                                    <CalendarDaysIcon className="h-6 w-6 text-blue-500 mr-2" />
                                    <h2 className="text-2xl font-bold">{goal.title}</h2>
                                    {firstDayPicture && lastDayPicture && (
                                        <CameraIcon className="h-6 w-6 text-green-500 ml-2" title="Before and After Image Available" />
                                    )}
                                </div>
                                <p className="text-gray-600">{goal.duration} days</p>
                                <p className="text-gray-600">Start Date: {new Date(goal.start_date).toLocaleDateString()}</p>
                                <p className="text-blue-600 font-semibold">Current Day: {currentDay}</p>
                                <p className="text-green-600 font-semibold">Completion: {completionPercentage}%</p>
                                <p className="text-red-600 font-semibold">Missed Days: {missedDays} {missedDaysEmoji}</p>
                                {goal.specific_days && goal.specific_days.length > 0 ? (
                                    <div className="mt-2">
                                        <p className="text-gray-600">Specific Days:</p>
                                        <div className="flex flex-wrap">
                                            {goal.specific_days.map((day, index) => (
                                                <span key={index} className="bg-gray-200 text-gray-600 px-2 py-1 rounded mr-1 mb-1">
                                                    {day}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <p className="text-gray-600">Everyday</p>
                                )}
                                <div className="mt-4 flex-grow">
                                    <h3 className="text-xl font-semibold mb-2">Progress</h3>
                                    <div className="grid grid-cols-7 gap-1">
                                        {goal.specific_days && goal.specific_days.length > 0
                                            ? generateSpecificDays(goal).map((date, index) => {
                                                  const dayString = (index + 1).toString();
                                                  return (
                                                      <button
                                                          key={index}
                                                          onClick={() =>
                                                              isTodayOrBefore(date.toISOString().split('T')[0]) &&
                                                              toggleComplete(goal, dayString)
                                                          }
                                                          className={`px-1 py-1 rounded text-xs ${
                                                              goal.progress[dayString]?.status === 'completed'
                                                                  ? 'bg-green-500 text-white'
                                                                  : goal.progress[dayString]?.status === 'missed'
                                                                  ? 'bg-red-500 text-white'
                                                                  : 'bg-gray-200 text-gray-600'
                                                          } ${!isTodayOrBefore(date.toISOString().split('T')[0]) && 'cursor-not-allowed'}`}
                                                          style={{ fontSize: '10px', minWidth: '30px', textAlign: 'center' }}
                                                          disabled={!isTodayOrBefore(date.toISOString().split('T')[0])}
                                                      >
                                                          {`${getDayName(date.toISOString().split('T')[0])} ${
                                                              date.getMonth() + 1
                                                          }/${date.getDate()}`}
                                                          {goal.progress[dayString]?.status === 'missed' && ' ❌'}
                                                      </button>
                                                  );
                                              })
                                            : generateEverydayDays(goal).map((date, index) => {
                                                  const dayString = (index + 1).toString();
                                                  return (
                                                      <button
                                                          key={index}
                                                          onClick={() =>
                                                              isTodayOrBefore(date.toISOString().split('T')[0]) &&
                                                              toggleComplete(goal, dayString)
                                                          }
                                                          className={`px-1 py-1 rounded text-xs ${
                                                              goal.progress[dayString]?.status === 'completed'
                                                                  ? 'bg-green-500 text-white'
                                                                  : goal.progress[dayString]?.status === 'missed'
                                                                  ? 'bg-red-500 text-white'
                                                                  : 'bg-gray-200 text-gray-600'
                                                          } ${!isTodayOrBefore(date.toISOString().split('T')[0]) && 'cursor-not-allowed'}`}
                                                          style={{ fontSize: '10px', minWidth: '30px', textAlign: 'center' }}
                                                          disabled={!isTodayOrBefore(date.toISOString().split('T')[0])}
                                                      >
                                                          {`${getDayName(date.toISOString().split('T')[0])} ${
                                                              date.getMonth() + 1
                                                          }/${date.getDate()}`}
                                                          {goal.progress[dayString]?.status === 'missed' && ' ❌'}
                                                      </button>
                                                  );
                                              })}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
            <audio ref={audioRef} src="/sound-effect.wav" />
            <audio ref={completeAudioRef} src="/complete-effect.wav" />
            <Transition appear show={isDeleteConfirmOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeDeleteConfirm}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Confirm Delete
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to delete this goal? This action cannot be undone.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={confirmDelete}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                                            onClick={closeDeleteConfirm}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <EditGoalForm isOpen={isEditFormOpen} goal={goalToEdit} onClose={() => setIsEditFormOpen(false)} setGoals={setGoals} refreshGoals={refreshGoals} />
            <ImageUploadPrompt
                isOpen={isImageUploadPromptOpen}
                onClose={() => setIsImageUploadPromptOpen(false)}
                onConfirm={() => {
                    document.getElementById('image-upload-input').click();
                }}
            />
            <input
                type="file"
                id="image-upload-input"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => handleImageUpload(goalForImageUpload, dayForImageUpload, e.target.files[0])}
            />
            <CollageDisplay isOpen={isCollageDisplayOpen} onClose={() => setIsCollageDisplayOpen(false)} collageUrl={collageUrl} />
        </div>
    );
};

const CollageDisplay = ({ isOpen, onClose, collageUrl }) => (
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Before and After Collage
                            </Dialog.Title>
                            <div className="mt-4">
                                {collageUrl ? (
                                    <img src={`${collageUrl}`} alt="Before and After Collage" className="w-full h-auto" />
                                ) : (
                                    <p className="text-sm text-gray-500">Failed to load collage image.</p>
                                )}
                            </div>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
);

export default GoalList;
