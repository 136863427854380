import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, UserIcon, PlusCircleIcon, ShareIcon, ChartBarIcon, KeyIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GoalForm from './GoalForm';
import Streak from './Streak';
import ChangePassword from './ChangePassword';
import InvitePartnerForm from './InvitePartnerForm';
import PartnerProgress from './PartnerProgress'; // Import PartnerProgress component
import { useSnackbar } from 'notistack';
import api from '../api'; // Import the api module
import Profile from './Profile';

const navigation = [
    { name: 'Share Progress', href: '#', icon: ShareIcon, current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Header = ({ refreshGoals, refreshLeaderboard, currentStreak, setCurrentStreak, updateLeaderboardUsername, fetchProgressData }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isGoalFormOpen, setIsGoalFormOpen] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isInvitePartnerOpen, setIsInvitePartnerOpen] = useState(false);
    const [isPartnerProgressOpen, setIsPartnerProgressOpen] = useState(false);
    const [selectedPartnerEmail, setSelectedPartnerEmail] = useState(null);
    const [pendingPartners, setPendingPartners] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const fetchStreak = async () => {
            try {
                const response = await api.get('/api/streak');
                if (response.status === 200) {
                    setCurrentStreak(response.data.streak);
                } else {
                    enqueueSnackbar('Failed to fetch streak', { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar(`Error fetching streak: ${error.message}`, { variant: 'error' });
            }
        };

        const fetchPendingPartners = async () => {
            try {
                const response = await api.get('/api/partners/pending');
                if (response.status === 200) {
                    setPendingPartners(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch pending partners', { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar(`Error fetching pending partners: ${error.message}`, { variant: 'error' });
            }
        };

        const fetchPartners = async () => {
            try {
                const response = await api.get('/api/partners');
                if (response.status === 200) {
                    setPartners(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch partners', { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar(`Error fetching partners: ${error.message}`, { variant: 'error' });
            }
        };

        fetchStreak();
        fetchPendingPartners();
        fetchPartners();
    }, [setCurrentStreak, enqueueSnackbar]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
        window.location.reload(); // Force a reload of the login page
    };

    const confirmPartner = async (partnerId, confirm) => {
        try {
            const response = await api.put(`/api/partners/confirm/${partnerId}`, { confirm });
            if (response.status === 200) {
                if (confirm) {
                    enqueueSnackbar('Partner request accepted successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar('Partner request declined successfully', { variant: 'success' });
                }
                setPendingPartners(pendingPartners.filter(partner => partner.partner_id !== partnerId));
            } else {
                enqueueSnackbar('Failed to process partner request', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar(`Error processing partner request: ${error.message}`, { variant: 'error' });
        }
    };

    return (
        <>
            <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            <div className="relative flex h-16 items-center justify-between">
                                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex items-center justify-center flex-1 lg:items-stretch lg:justify-start">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-8 w-auto"
                                            src="/G.png"
                                            alt="Goal Tracker Online"
                                        />
                                    </div>
                                    <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                                        {navigation.map((item) => (
                                            <button
                                                key={item.name}
                                                onClick={() => {
                                                    if (item.name === 'Share Progress') {
                                                        fetchProgressData();
                                                    }
                                                }}
                                                className={classNames(
                                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium flex items-center'
                                                )}
                                            >
                                                <item.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                                                {item.name}
                                            </button>
                                        ))}
                                        <button
                                            onClick={() => setIsGoalFormOpen(true)}
                                            className="bg-gray-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                        >
                                            <PlusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                            Add New Goal
                                        </button>
                                        <button
                                            onClick={() => setIsInvitePartnerOpen(true)} // Open InvitePartnerForm
                                            className="bg-gray-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                        >
                                            <UserIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                            Invite Partner
                                        </button>
                                        {partners.length > 0 && (
                                            <button
                                                onClick={() => {
                                                    setSelectedPartnerEmail(partners[0].partner_email); // Set the first partner as an example
                                                    setIsPartnerProgressOpen(true);
                                                }}
                                                className="bg-gray-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                            >
                                                <ChartBarIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                                Check Partner Progress
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <Streak currentStreak={currentStreak} />
                                    <Menu as="div" className="relative">
                                        <Menu.Button
                                            type="button"
                                            className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            {pendingPartners.length > 0 && (
                                                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                                    {pendingPartners.length}
                                                </span>
                                            )}
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {pendingPartners.length === 0 ? (
                                                    <Menu.Item>
                                                        <span className="block px-4 py-2 text-sm text-gray-700">No notifications</span>
                                                    </Menu.Item>
                                                ) : (
                                                    pendingPartners.map(partner => (
                                                        <Menu.Item key={partner.partner_id}>
                                                            <div className="flex flex-col px-4 py-2 text-sm text-gray-700">
                                                                <span className="mb-1">
                                                                    {partner.username} invited you
                                                                </span>
                                                                <div className="flex space-x-2">
                                                                    <button
                                                                        onClick={() => confirmPartner(partner.partner_id, true)}
                                                                        className="bg-green-500 text-white rounded-md px-2 py-1 hover:bg-green-700"
                                                                    >
                                                                        Yes
                                                                    </button>
                                                                    <button
                                                                        onClick={() => confirmPartner(partner.partner_id, false)}
                                                                        className="bg-red-500 text-white rounded-md px-2 py-1 hover:bg-red-700"
                                                                    >
                                                                        No
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Menu.Item>
                                                    ))
                                                )}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span className="sr-only">Open user menu</span>
                                                <UserIcon className="h-8 w-8 rounded-full text-gray-400" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={() => setIsChangePasswordOpen(true)}
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700 flex items-center')}
                                                        >
                                                            <KeyIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                                            Change Password
                                                        </button>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={() => setIsProfileOpen(true)}
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700 flex items-center')}
                                                        >
                                                            <UserIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                                            Profile
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                                
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={handleLogout}
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700 flex items-center')}
                                                        >
                                                            <ArrowRightOnRectangleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                                            Sign out
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="lg:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="button"
                                        onClick={() => {
                                            if (item.name === 'Share Progress') {
                                                fetchProgressData();
                                            }
                                        }}
                                        className={classNames(
                                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium flex items-center'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        <item.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                                <button
                                    onClick={() => setIsGoalFormOpen(true)}
                                    className="bg-gray-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                >
                                    <PlusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                    Add New Goal
                                </button>
                                <button
                                    onClick={() => setIsInvitePartnerOpen(true)} // Open InvitePartnerForm
                                    className="bg-gray-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                >
                                    <UserIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                    Invite Partner
                                </button>
                                {partners.length > 0 && (
                                    <button
                                        onClick={() => {
                                            setSelectedPartnerEmail(partners[0].partner_email); // Set the first partner as an example
                                            setIsPartnerProgressOpen(true);
                                        }}
                                        className="bg-gray-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                    >
                                        <ChartBarIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                        Check Partner Progress
                                    </button>
                                )}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <GoalForm
                isOpen={isGoalFormOpen}
                onClose={() => setIsGoalFormOpen(false)}
                refreshGoals={refreshGoals}
                refreshLeaderboard={refreshLeaderboard}
                setCurrentStreak={setCurrentStreak} // Pass the setCurrentStreak function
            />
            <InvitePartnerForm
                isOpen={isInvitePartnerOpen}
                onClose={() => setIsInvitePartnerOpen(false)} // Close InvitePartnerForm
            />
            <Transition
                show={isChangePasswordOpen}
                as={Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <ChangePassword />
                                <button
                                    onClick={() => setIsChangePasswordOpen(false)}
                                    className="mt-5 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Close
                                </button>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
            <Transition
                show={isProfileOpen}
                as={Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <Profile />
                                <button
                                    onClick={() => setIsProfileOpen(false)}
                                    className="mt-5 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Close
                                </button>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
            <Transition
                show={isPartnerProgressOpen}
                as={Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                                <PartnerProgress
                                    partnerEmail={selectedPartnerEmail}
                                    onClose={() => setIsPartnerProgressOpen(false)}
                                />
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default Header;
